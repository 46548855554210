<template>
  <div style="position: relative">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="	height: 40px;">
      <el-breadcrumb-item>{{ fun.getLanguageText($store.state.language, '设备管理') }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ fun.getLanguageText($store.state.language, '设备列表') }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ fun.getLanguageText($store.state.language, '参数设定') }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="fun.getLanguageText($store.state.language, '基本设定')" name="system">
        <el-form :inline="true" label-position="left" label-width="200px" status-icon>
          <el-form-item :label="fun.getLanguageText($store.state.language, '有效信号认定时长大于')">
            <el-input v-model.number="system.effectSignalTime"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, '秒(默认两秒)') }}</span>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '泵开启后冲水时间')">
            <el-input v-model.number="system.pumpFlushTime" autocomplete="off"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, '秒') }}</span>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '药剂间冲水时间')">
            <el-input v-model.number="system.reagentFlushTime" autocomplete="off"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, '秒(默认两秒)') }}</span>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '泵体最长执行时间')">
            <el-input v-model.number="system.pumpMaxTime"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, '秒(超过时间关停)') }}</span>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '气冲时间')">
            <el-input v-model.number="system.airFlushTime"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, '秒') }}</span>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '最终冲水时间')">
            <el-input v-model.number="system.finalFlushTime"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, '秒') }}</span>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '工作模式选择')" style="width: 100%;">
            <el-radio-group v-model="radio_system">
              <el-radio :label="1">{{ fun.getLanguageText($store.state.language, '时间模式') }}</el-radio>
              <el-radio :label="2">{{ fun.getLanguageText($store.state.language, '编程模式') }}</el-radio>
              <el-radio :label="3">{{ fun.getLanguageText($store.state.language, '手动模式') }}</el-radio>
              <el-radio :label="4">{{ fun.getLanguageText($store.state.language, '泵校准模式') }}</el-radio>
              <el-radio :label="5">{{ fun.getLanguageText($store.state.language, '加料模式') }}</el-radio>
              <el-radio :label="6">{{ fun.getLanguageText($store.state.language, '远程模式') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" style="width: 100%;">
            <el-button :disabled="stat.remoteStat != 1" style="margin-left: 200px; width: 200px;" type="primary"
              @click="set_system">{{ fun.getLanguageText($store.state.language, '修改') }}
            </el-button>
            <el-button style="margin-left: 200px; width: 200px;" type="primary" @click="get_system(false)">
              {{ fun.getLanguageText($store.state.language, '读取') }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane class="set1" :label="fun.getLanguageText($store.state.language, '配方设定1')" name="b">
        <div class="title"> {{ fun.getLanguageText($store.state.language, '配方参数设定') }}</div>
        <el-table :data="tableData" :max-height="$store.state.clientHeight" class="mall" style="width: 100%">
          <el-table-column label="" width="110">
            <template slot-scope="scope">
              <span>{{ scope.$index === 0 ? fun.getLanguageText($store.state.language, '配方编号') : scope.$index }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '洗衣龙')" prop="date" width="200">
            <el-table-column prop="name" width="200">
              <!-- label="加药量设定(毫升)" -->
              <template slot-scope="scope">
                <el-input v-if="scope.row.name !== '配方名称\\药剂名称'" v-model="scope.row.name"
                  :placeholder="fun.getLanguageText($store.state.language, '请输入内容')"></el-input>
                <span v-else>{{ fun.getLanguageText($store.state.language, '配方名称\\药剂名称') }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '洗衣龙1')">
            <el-table-column prop="1">
              <template slot-scope="scope">
                <el-input v-model="scope.row[1]"
                  :placeholder="fun.getLanguageText($store.state.language, '请输入内容')"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="2">
              <template slot-scope="scope">
                <el-input v-model="scope.row[2]"
                  :placeholder="fun.getLanguageText($store.state.language, '请输入内容')"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="3">
              <template slot-scope="scope">
                <el-input v-model="scope.row[3]"
                  :placeholder="fun.getLanguageText($store.state.language, '请输入内容')"></el-input>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '洗衣龙2')">
            <el-table-column prop="4">
              <template slot-scope="scope">
                <el-input v-model="scope.row[4]"
                  :placeholder="fun.getLanguageText($store.state.language, '请输入内容')"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="5">
              <template slot-scope="scope">
                <el-input v-model="scope.row[5]"
                  :placeholder="fun.getLanguageText($store.state.language, '请输入内容')"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="6">
              <template slot-scope="scope">
                <el-input v-model="scope.row[6]"
                  :placeholder="fun.getLanguageText($store.state.language, '请输入内容')"></el-input>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '洗衣龙3')">
            <el-table-column prop="7">
              <template slot-scope="scope">
                <el-input v-model="scope.row[7]"
                  :placeholder="fun.getLanguageText($store.state.language, '请输入内容')"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="8">
              <template slot-scope="scope">
                <el-input v-model="scope.row[8]"
                  :placeholder="fun.getLanguageText($store.state.language, '请输入内容')"></el-input>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '洗衣龙4')">
            <el-table-column prop="9">
              <template slot-scope="scope">
                <el-input v-model="scope.row[9]"
                  :placeholder="fun.getLanguageText($store.state.language, '请输入内容')"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="10">
              <template slot-scope="scope">
                <el-input v-model="scope.row[10]"
                  :placeholder="fun.getLanguageText($store.state.language, '请输入内容')"></el-input>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <el-form style="margin-top: 8px;">
          <el-form-item label="" style="width: 100%;">
            <el-button :disabled="stat.remoteStat != 1" style="margin-left: 200px; width: 200px;" type="primary"
              @click="set_formulation">{{ fun.getLanguageText($store.state.language, '修改') }}
            </el-button>
            <el-button style="margin-left: 200px; width: 200px;" type="primary" @click="get_formulation(false)">{{
              fun.getLanguageText($store.state.language, '读取') }}
            </el-button>
          </el-form-item>
        </el-form>

      </el-tab-pane>
      <el-tab-pane :label="fun.getLanguageText($store.state.language, '配方设定2')" name="second">
        <el-form :inline="true" class="second" label-position="left" label-width="100px" status-icon>
          <el-form-item label="" prop="username" style="width: 100%; margin-bottom: 0;">
            <span style="width: 300px; height: 0; display: inline-block;"></span>
            <span style="width: 135px; display: inline-block; text-align: center;">{{
              fun.getLanguageText($store.state.language, '泵')
            }}1</span>
            <span style="width: 135px; display: inline-block; text-align: center;margin-left: 20px;">{{
              fun.getLanguageText($store.state.language, '泵')
            }}2</span>
            <span style="width: 135px; display: inline-block; text-align: center;margin-left: 20px;">{{
              fun.getLanguageText($store.state.language, '泵')
            }}3</span>
            <span style="width: 135px; display: inline-block; text-align: center;margin-left: 20px;">{{
              fun.getLanguageText($store.state.language, '泵')
            }}4</span>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '气冲时间(秒)')" label-width="300px" prop="username"
            style="width: 100%;">
            <el-input v-model="rinse.pump_1_air" style="width: 135px;"></el-input>
            <el-input v-model="rinse.pump_2_air" style="width: 135px; margin-left: 20px;"></el-input>
            <el-input v-model="rinse.pump_3_air" style="width: 135px; margin-left: 20px;"></el-input>
            <el-input v-model="rinse.pump_4_air" style="width: 135px; margin-left: 20px;"></el-input>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '水冲时间(秒)')" label-width="300px" prop="username"
            style="width: 100%;">
            <el-input v-model="rinse.pump_1_water" style="width: 135px;"></el-input>
            <el-input v-model="rinse.pump_2_water" style="width: 135px; margin-left: 20px;"></el-input>
            <el-input v-model="rinse.pump_3_water" style="width: 135px; margin-left: 20px;"></el-input>
            <el-input v-model="rinse.pump_4_water" style="width: 135px; margin-left: 20px;"></el-input>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '最终水冲时间(秒)')" label-width="300px"
            prop="username" style="width: 100%;">
            <el-input v-model="rinse.pump_1_final" style="width: 135px;"></el-input>
            <el-input v-model="rinse.pump_2_final" style="width: 135px; margin-left: 20px;"></el-input>
            <el-input v-model="rinse.pump_3_final" style="width: 135px; margin-left: 20px;"></el-input>
            <el-input v-model="rinse.pump_4_final" style="width: 135px; margin-left: 20px;"></el-input>
          </el-form-item>
          <el-form-item label="" style="width: 100%;">
            <el-button :disabled="stat.remoteStat != 1" style="margin-left: 120px; width: 200px;" type="primary"
              @click="set_rinse">{{ fun.getLanguageText($store.state.language, '修改') }}
            </el-button>
            <el-button style="margin-left: 110px; width: 200px;" type="primary" @click="get_rinse(false)">
              {{ fun.getLanguageText($store.state.language, '读取') }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane class="price" :label="fun.getLanguageText($store.state.language, '化料单价设定')" name="third">
        <el-form :inline="true" label-position="left" label-width="200px" status-icon>
          <el-form-item :label="'1-' + price.medicamentName_1">
            <el-input v-model="price.medicament_1_price"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, 'RMB/Liter') }}</span>
          </el-form-item>
          <el-form-item :label="'6-' + price.medicamentName_6" prop="username">
            <el-input v-model="price.medicament_6_price"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, 'RMB/Liter') }}</span>
          </el-form-item>
          <el-form-item :label="'2-' + price.medicamentName_2" prop="username">
            <el-input v-model="price.medicament_2_price"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, 'RMB/Liter') }}</span>
          </el-form-item>
          <el-form-item :label="'7-' + price.medicamentName_7" prop="username">
            <el-input v-model="price.medicament_7_price"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, 'RMB/Liter') }}</span>
          </el-form-item>
          <el-form-item :label="'3-' + price.medicamentName_3" prop="username">
            <el-input v-model="price.medicament_3_price"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, 'RMB/Liter') }}</span>
          </el-form-item>
          <el-form-item :label="'8-' + price.medicamentName_8" prop="username">
            <el-input v-model="price.medicament_8_price"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, 'RMB/Liter') }}</span>
          </el-form-item>
          <el-form-item :label="'4-' + price.medicamentName_4" prop="username">
            <el-input v-model="price.medicament_4_price"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, 'RMB/Liter') }}</span>
          </el-form-item>
          <el-form-item :label="'9-' + price.medicamentName_9" prop="username">
            <el-input v-model="price.medicament_9_price"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, 'RMB/Liter') }}</span>
          </el-form-item>
          <el-form-item :label="'5-' + price.medicamentName_5" prop="username">
            <el-input v-model="price.medicament_5_price"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, 'RMB/Liter') }}</span>
          </el-form-item>
          <el-form-item :label="'10-' + price.medicamentName_10" prop="username">
            <el-input v-model="price.medicament_10_price"></el-input>
            <span class="el-input-none">{{ fun.getLanguageText($store.state.language, 'RMB/Liter') }}</span>
          </el-form-item>
          <el-form-item label="" prop="username" style="width: 100%;">
            <el-button :disabled="stat.remoteStat != 1" style="margin-left: 120px; width: 200px;" type="primary"
              @click="set_price">{{ fun.getLanguageText($store.state.language, '修改') }}
            </el-button>
            <el-button style="margin-left: 110px; width: 200px;" type="primary" @click="get_price(false)">
              {{ fun.getLanguageText($store.state.language, '读取') }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <!--      <el-tab-pane class="aline" label="泵校准" name="pump-calibration">
              <el-form :inline="true" label-position="left" label-width="100px" status-icon>
                <el-form-item label="-" prop="username">
                  <span class="el-input-none" style="display: inline-block; width: 110px;">毫升</span>
                  <span class="el-input-none" style="display: inline-block; width: 70px;">秒</span>
                  <span class="el-input-none" style="display: inline-block; width: 100px;">修正值</span>
                </el-form-item>
                <el-form-item label="-" prop="username">
                  <span class="el-input-none" style="display: inline-block; width: 110px;">毫升</span>
                  <span class="el-input-none" style="display: inline-block; width: 70px;">秒</span>
                  <span class="el-input-none" style="display: inline-block; width: 100px;">修正值</span>
                </el-form-item>
                <el-form-item label="药剂名称1" prop="username">
                  <el-input v-model="pump.medicament_1_capacity" style="width: 80px;"></el-input>
                  <span class="el-input-none">/</span>
                  <el-input v-model="pump.medicament_1_time" style="width: 80px; margin-left: 20px;"></el-input>
                  <el-input v-model="pump.medicament_1_correction" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称2" prop="username">
                  <el-input v-model="pump.medicament_2_capacity" style="width: 80px;"></el-input>
                  <span class="el-input-none">/</span>
                  <el-input v-model="pump.medicament_2_time" style="width: 80px; margin-left: 20px;"></el-input>
                  <el-input v-model="pump.medicament_2_correction" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称3" prop="username">
                  <el-input v-model="pump.medicament_3_capacity" style="width: 80px;"></el-input>
                  <span class="el-input-none">/</span>
                  <el-input v-model="pump.medicament_3_time" style="width: 80px; margin-left: 20px;"></el-input>
                  <el-input v-model="pump.medicament_3_correction" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称4" prop="username">
                  <el-input v-model="pump.medicament_4_capacity" style="width: 80px;"></el-input>
                  <span class="el-input-none">/</span>
                  <el-input v-model="pump.medicament_4_time" style="width: 80px; margin-left: 20px;"></el-input>
                  <el-input v-model="pump.medicament_4_correction" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称5" prop="username">
                  <el-input v-model="pump.medicament_5_capacity" style="width: 80px;"></el-input>
                  <span class="el-input-none">/</span>
                  <el-input v-model="pump.medicament_5_time" style="width: 80px; margin-left: 20px;"></el-input>
                  <el-input v-model="pump.medicament_5_correction" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称6" prop="username">
                  <el-input v-model="pump.medicament_6_capacity" style="width: 80px;"></el-input>
                  <span class="el-input-none">/</span>
                  <el-input v-model="pump.medicament_6_time" style="width: 80px; margin-left: 20px;"></el-input>
                  <el-input v-model="pump.medicament_6_correction" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称7" prop="username">
                  <el-input v-model="pump.medicament_7_capacity" style="width: 80px;"></el-input>
                  <span class="el-input-none">/</span>
                  <el-input v-model="pump.medicament_7_time" style="width: 80px; margin-left: 20px;"></el-input>
                  <el-input v-model="pump.medicament_7_correction" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称8" prop="username">
                  <el-input v-model="pump.medicament_8_capacity" style="width: 80px;"></el-input>
                  <span class="el-input-none">/</span>
                  <el-input v-model="pump.medicament_8_time" style="width: 80px; margin-left: 20px;"></el-input>
                  <el-input v-model="pump.medicament_8_correction" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称9" prop="username">
                  <el-input v-model="pump.medicament_9_capacity" style="width: 80px;"></el-input>
                  <span class="el-input-none">/</span>
                  <el-input v-model="pump.medicament_9_time" style="width: 80px; margin-left: 20px;"></el-input>
                  <el-input v-model="pump.medicament_9_correction" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称10" prop="username">
                  <el-input v-model="pump.medicament_10_capacity" style="width: 80px;"></el-input>
                  <span class="el-input-none">/</span>
                  <el-input v-model="pump.medicament_10_time" style="width: 80px; margin-left: 20px;"></el-input>
                  <el-input v-model="pump.medicament_10_correction" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="泵1" prop="username">
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0;" type="primary">
                    气冲
                  </el-button>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0;" type="primary">
                    水冲
                  </el-button>
                </el-form-item>
                <el-form-item label="泵2" prop="username">
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0;" type="primary">
                    气冲
                  </el-button>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0;" type="primary">
                    水冲
                  </el-button>
                </el-form-item>
                <el-form-item label="泵3" prop="username">
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0;" type="primary">
                    气冲
                  </el-button>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0;" type="primary">
                    水冲
                  </el-button>
                </el-form-item>
                <el-form-item label="泵4" prop="username">
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0;" type="primary">
                    气冲
                  </el-button>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0;" type="primary">
                    水冲
                  </el-button>
                </el-form-item>
                <el-form-item label="" prop="username" style="width: 100%;">
                  <el-button style="margin-left: 100px; " type="primary" @click="set_pump">修正值设定</el-button>
                  <el-button style="margin-left: 100px; " type="primary" @click="get_pump_calibration(false)">读取
                  </el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>-->
      <!--      <el-tab-pane class="aline" label="首次加料" name="first-feed">
              <el-form :inline="true" label-position="left" label-width="100px" status-icon>
                <el-form-item label="配方名称" prop="username">
                  <el-select v-model="value" placeholder="请选择"></el-select>
                </el-form-item>
                <el-form-item label="" prop="username">
                </el-form-item>
                <el-form-item label="药剂名称1" prop="username">
                  <el-input v-model="first_feed.medicamentName_1" disabled style="width: 80px;"></el-input>
                  <el-input v-model="first_feed.medicamentTime_1" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <span class="el-input-none">秒</span>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称2" prop="username">
                  <el-input v-model="first_feed.medicamentName_2" disabled style="width: 80px;"></el-input>
                  <el-input v-model="first_feed.medicamentTime_2" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <span class="el-input-none">秒</span>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称3" prop="username">
                  <el-input v-model="first_feed.medicamentName_3" disabled style="width: 80px;"></el-input>
                  <el-input v-model="first_feed.medicamentTime_3" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <span class="el-input-none">秒</span>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称4" prop="username">
                  <el-input v-model="first_feed.medicamentName_4" disabled style="width: 80px;"></el-input>
                  <el-input v-model="first_feed.medicamentTime_4" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <span class="el-input-none">秒</span>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称5" prop="username">
                  <el-input v-model="first_feed.medicamentName_5" disabled style="width: 80px;"></el-input>
                  <el-input v-model="first_feed.medicamentTime_5" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <span class="el-input-none">秒</span>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称6" prop="username">
                  <el-input v-model="first_feed.medicamentName_6" disabled style="width: 80px;"></el-input>
                  <el-input v-model="first_feed.medicamentTime_6" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <span class="el-input-none">秒</span>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称7" prop="username">
                  <el-input v-model="first_feed.medicamentName_7" disabled style="width: 80px;"></el-input>
                  <el-input v-model="first_feed.medicamentTime_7" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <span class="el-input-none">秒</span>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称8" prop="username">
                  <el-input v-model="first_feed.medicamentName_8" disabled style="width: 80px;"></el-input>
                  <el-input v-model="first_feed.medicamentTime_8" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <span class="el-input-none">秒</span>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称9" prop="username">
                  <el-input v-model="first_feed.medicamentName_9" disabled style="width: 80px;"></el-input>
                  <el-input v-model="first_feed.medicamentTime_9" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <span class="el-input-none">秒</span>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="药剂名称10" prop="username">
                  <el-input v-model="first_feed.medicamentName_10" disabled style="width: 80px;"></el-input>
                  <el-input v-model="first_feed.medicamentTime_10" style="width: 80px; margin-left: 20px;">
                  </el-input>
                  <span class="el-input-none">秒</span>
                  <el-button plain style="padding: 0; height: 40px; width: 80px; margin-right: 0; margin-left: 20px;"
                             type="primary">校准
                  </el-button>
                </el-form-item>
                <el-form-item label="" prop="username" style="width: 100%;">
                  <el-button style="margin-left: 200px; width: 200px;" type="primary" @click="set_first_feed">修改
                  </el-button>
                  <el-button style="margin-left: 200px; width: 200px;" type="primary"
                             @click="get_first_feed(false)">读取
                  </el-button>
                </el-form-item>
              </el-form>


            </el-tab-pane>-->
      <el-tab-pane class="aline" :label="fun.getLanguageText($store.state.language, '药剂系数修正')" name="reagent">
        <el-form :inline="true" label-position="left" label-width="150px" status-icon>
          <el-form-item label="">
            {{ fun.getLanguageText($store.state.language, '编号') }}
            <span class="el-input-none" style="margin-left: 100px;">A</span>
            <span class="el-input-none" style="margin-left: 100px;">X</span>
            <span class="el-input-none" style="margin-left: 100px;">B</span>
          </el-form-item>
          <el-form-item label="">
            {{ fun.getLanguageText($store.state.language, '编号') }}
            <span class="el-input-none" style="margin-left: 100px;">A</span>
            <span class="el-input-none" style="margin-left: 100px;">X</span>
            <span class="el-input-none" style="margin-left: 100px;">B</span>
          </el-form-item>
          <el-form-item :label="'1--' + first_feed.medicamentName_1">
            <el-input v-model="reagent.medicament_1_A" style="width: 100px;"></el-input>
            <span class="el-input-none">X</span>
            <el-input v-model="reagent.medicament_1_X" disabled style="width: 100px;"></el-input>
            <span class="el-input-none">➕</span>
            <el-input v-model="reagent.medicament_1_B" style="width: 100px;"></el-input>
          </el-form-item>
          <el-form-item :label="'6--' + first_feed.medicamentName_6">
            <el-input v-model="reagent.medicament_6_A" style="width: 100px;"></el-input>
            <span class="el-input-none">X</span>
            <el-input v-model="reagent.medicament_6_X" disabled style="width: 100px;"></el-input>
            <span class="el-input-none">➕</span>
            <el-input v-model="reagent.medicament_6_B" style="width: 100px; "></el-input>
          </el-form-item>
          <el-form-item :label="'2--' + first_feed.medicamentName_2">
            <el-input v-model="reagent.medicament_2_A" style="width: 100px;"></el-input>
            <span class="el-input-none">X</span>
            <el-input v-model="reagent.medicament_2_X" disabled style="width: 100px;"></el-input>
            <span class="el-input-none">➕</span>
            <el-input v-model="reagent.medicament_2_B" style="width: 100px; "></el-input>
          </el-form-item>
          <el-form-item :label="'7--' + first_feed.medicamentName_7">
            <el-input v-model="reagent.medicament_7_A" style="width: 100px;"></el-input>
            <span class="el-input-none">X</span>
            <el-input v-model="reagent.medicament_7_X" disabled style="width: 100px;"></el-input>
            <span class="el-input-none">➕</span>
            <el-input v-model="reagent.medicament_7_B" style="width: 100px; "></el-input>
          </el-form-item>
          <el-form-item :label="'3--' + first_feed.medicamentName_3">
            <el-input v-model="reagent.medicament_3_A" style="width: 100px;"></el-input>
            <span class="el-input-none">X</span>
            <el-input v-model="reagent.medicament_3_X" disabled style="width: 100px;"></el-input>
            <span class="el-input-none">➕</span>
            <el-input v-model="reagent.medicament_3_B" style="width: 100px; "></el-input>
          </el-form-item>
          <el-form-item :label="'8--' + first_feed.medicamentName_8">
            <el-input v-model="reagent.medicament_8_A" style="width: 100px;"></el-input>
            <span class="el-input-none">X</span>
            <el-input v-model="reagent.medicament_8_X" disabled style="width: 100px;"></el-input>
            <span class="el-input-none">➕</span>
            <el-input v-model="reagent.medicament_8_B" style="width: 100px; "></el-input>
          </el-form-item>
          <el-form-item :label="'4--' + first_feed.medicamentName_4">
            <el-input v-model="reagent.medicament_4_A" style="width: 100px;"></el-input>
            <span class="el-input-none">X</span>
            <el-input v-model="reagent.medicament_4_X" disabled style="width: 100px;"></el-input>
            <span class="el-input-none">➕</span>
            <el-input v-model="reagent.medicament_4_B" style="width: 100px; "></el-input>
          </el-form-item>
          <el-form-item :label="'9--' + first_feed.medicamentName_9">
            <el-input v-model="reagent.medicament_9_A" style="width: 100px;"></el-input>
            <span class="el-input-none">X</span>
            <el-input v-model="reagent.medicament_9_X" disabled style="width: 100px;"></el-input>
            <span class="el-input-none">➕</span>
            <el-input v-model="reagent.medicament_9_B" style="width: 100px; "></el-input>
          </el-form-item>
          <el-form-item :label="'5--' + first_feed.medicamentName_5">
            <el-input v-model="reagent.medicament_5_A" style="width: 100px;"></el-input>
            <span class="el-input-none">X</span>
            <el-input v-model="reagent.medicament_5_X" disabled style="width: 100px;"></el-input>
            <span class="el-input-none">➕</span>
            <el-input v-model="reagent.medicament_5_B" style="width: 100px; "></el-input>
          </el-form-item>
          <el-form-item :label="'10--' + first_feed.medicamentName_10">
            <el-input v-model="reagent.medicament_10_A" style="width: 100px;"></el-input>
            <span class="el-input-none">X</span>
            <el-input v-model="reagent.medicament_10_X" disabled style="width: 100px;"></el-input>
            <span class="el-input-none">➕</span>
            <el-input v-model="reagent.medicament_10_B" style="width: 100px; "></el-input>
          </el-form-item>
          <el-form-item label="" prop="username" style="width: 100%;">
            <el-button :disabled="stat.remoteStat != 1" style="margin-left: 200px; width: 200px;" type="primary"
              @click="set_reagent"> {{ fun.getLanguageText($store.state.language, '修改') }}
            </el-button>
            <el-button style="margin-left: 200px; width: 200px;" type="primary" @click="get_reagent(false)">
              {{ fun.getLanguageText($store.state.language, '读取') }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-button style="position: absolute;right: 0;top: 0" type="primary" @click="downloadExcel">
      {{ fun.getLanguageText($store.state.language, '导出表单(配方)') }}
    </el-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      radio_system: 1,
      tableData: [],
      value: '',
      activeName: 'system',
      formData: {
        username: '',
        password: '',
        email: '',
        type: '',
        corporateName: ''
      },
      rinse: {
        "pump_3_final": "0",
        "pump_1_air": "0",
        "pump_4_air": "0",
        "pump_1_water": "0",
        "pump_2_water": "0",
        "pump_3_water": "0",
        "pump_4_water": "0",
        "pump_2_air": "0",
        "pump_3_air": "0",
        "pump_1_final": "0",
        "pump_2_final": "0",
        "pump_4_final": "0"
      },
      system: {
        effectSignalTime: '',
        pumpMaxTime: '',
        pumpFlushTime: '',
        reagentFlushTime: '',
        finalFlushTime: '',
        airFlushTime: ''
      },
      first_feed: {
        medicamentName_1: '',
        medicamentName_2: '',
        medicamentName_3: '',
        medicamentName_4: '',
        medicamentName_5: '',
        medicamentName_6: '',
        medicamentName_7: '',
        medicamentName_8: '',
        medicamentName_9: '',
        medicamentName_10: '',
        medicamentTime_1: '',
        medicamentTime_2: '',
        medicamentTime_3: '',
        medicamentTime_4: '',
        medicamentTime_5: '',
        medicamentTime_6: '',
        medicamentTime_7: '',
        medicamentTime_8: '',
        medicamentTime_9: '',
        medicamentTime_10: '',
      },
      reagent: {
        "medicament_7_A": "0.0",
        "medicament_10_A": "0.0",
        "medicament_1_A": "1.10",
        "medicament_2_A": "0.0",
        "medicament_3_A": "0.0",
        "medicament_3_B": "0.0",
        "medicament_10_B": "0.0",
        "medicament_1_B": "0.0",
        "medicament_2_B": "3.30",
        "medicament_4_B": "0.0",
        "medicament_7_B": "0.0",
        "medicament_8_B": "0.0",
        "medicament_4_A": "0.0",
        "medicament_5_A": "0.0",
        "medicament_8_A": "0.0",
        "medicament_9_B": "0.0",
        "medicament_6_B": "0.0",
        "medicament_6_A": "0.0",
        "medicament_9_A": "0.0",
        "medicament_5_B": "0.0"
      },
      formulation: {
        "medicamentName_9_volume_9": "0",
        "medicamentName_9_volume_11": "0",
        "medicamentName_2_volume_7": "0",
        "medicamentName_2_volume_10": "0",
        "medicamentName_4_volume_10": "0",
        "medicamentName_6_volume_7": "0",
        "medicamentName_9_volume_6": "0",
        "medicamentName_7_volume_10": "0",
        "medicamentName_8_volume_7": "0",
        "medicamentName_10_volume_5": "0",
        "medicamentName_2_volume_5": "0",
        "medicamentName_4_volume_2": "0",
        "medicamentName_4_volume_7": "0",
        "medicamentName_5_volume_3": "0",
        "medicamentName_7_volume_8": "0",
        "medicamentName_10_volume_7": "0",
        "medicamentName_10_volume_10": "0",
        "medicamentName_10_volume_14": "0",
        "medicamentName_9_volume_7": "0",
        "medicamentName_9_volume_13": "0",
        "medicamentName_1_volume_14": "0",
        "medicamentName_2": "",
        "medicamentName_6": "",
        "medicamentName_6_volume_3": "0",
        "medicamentName_6_volume_15": "0",
        "medicamentName_8_volume_15": "0",
        "medicamentName_9_volume_10": "0",
        "medicamentName_10_volume_3": "0",
        "medicamentName_4_volume_11": "0",
        "medicamentName_5_volume_15": "0",
        "medicamentName_6_volume_5": "0",
        "medicamentName_6_volume_6": "0",
        "medicamentName_7_volume_5": "0",
        "medicamentName_10_volume_9": "0",
        "medicamentName_7_volume_6": "0",
        "medicamentName_8_volume_6": "0",
        "medicamentName_8_volume_14": "0",
        "medicamentName_1_volume_6": "0",
        "medicamentName_2_volume_8": "0",
        "medicamentName_3_volume_14": "0",
        "medicamentName_4_volume_14": "0",
        "medicamentName_7_volume_1": "0",
        "medicamentName_2_volume_4": "0",
        "medicamentName_9": "",
        "medicamentName_2_volume_13": "0",
        "medicamentName_3_volume_3": "0",
        "medicamentName_9_volume_8": "0",
        "medicamentName_9_volume_14": "0",
        "medicamentName_9_volume_1": "0",
        "medicamentName_1_volume_5": "0",
        "medicamentName_2_volume_2": "0",
        "medicamentName_3_volume_1": "0",
        "medicamentName_3_volume_9": "0",
        "medicamentName_6_volume_13": "0",
        "medicamentName_1_volume_7": "0",
        "medicamentName_2_volume_15": "0",
        "medicamentName_3_volume_7": "0",
        "medicamentName_5_volume_4": "0",
        "medicamentName_5_volume_11": "0",
        "medicamentName_9_volume_2": "0",
        "medicamentName_9_volume_5": "0",
        "medicamentName_10_volume_1": "0",
        "medicamentName_1_volume_12": "0",
        "medicamentName_3_volume_6": "0",
        "medicamentName_3_volume_13": "0",
        "medicamentName_5_volume_14": "0",
        "medicamentName_7_volume_4": "0",
        "medicamentName_10_volume_8": "0",
        "medicamentName_5_volume_6": "0",
        "medicamentName_8": "",
        "medicamentName_8_volume_11": "0",
        "medicamentName_2_volume_6": "0",
        "medicamentName_3_volume_4": "0",
        "medicamentName_1_volume_9": "0",
        "medicamentName_4_volume_8": "0",
        "medicamentName_5_volume_10": "0",
        "medicamentName_6_volume_11": "0",
        "medicamentName_8_volume_3": "0",
        "medicamentName_1_volume_8": "0",
        "medicamentName_6_volume_12": "0",
        "medicamentName_10_volume_2": "0",
        "medicamentName_4_volume_6": "0",
        "medicamentName_5_volume_1": "0",
        "medicamentName_6_volume_10": "0",
        "medicamentName_8_volume_1": "0",
        "medicamentName_8_volume_8": "0",
        "medicamentName_2_volume_9": "0",
        "medicamentName_4_volume_4": "0",
        "medicamentName_9_volume_4": "0",
        "medicamentName_10_volume_13": "0",
        "medicamentName_10_volume_15": "0",
        "medicamentName_1_volume_2": "0",
        "medicamentName_2_volume_14": "0",
        "medicamentName_3_volume_5": "0",
        "medicamentName_3_volume_12": "0",
        "medicamentName_10_volume_6": "0",
        "medicamentName_3_volume_10": "0",
        "medicamentName_7_volume_15": "0",
        "medicamentName_4_volume_13": "0",
        "medicamentName_7_volume_12": "0",
        "medicamentName_8_volume_5": "0",
        "medicamentName_3_volume_15": "0",
        "medicamentName_4_volume_15": "0",
        "medicamentName_5_volume_9": "0",
        "medicamentName_5_volume_13": "0",
        "medicamentName_10_volume_4": "0",
        "medicamentName_2_volume_3": "0",
        "medicamentName_6_volume_9": "0",
        "medicamentName_7_volume_11": "0",
        "medicamentName_10": "",
        "medicamentName_10_volume_11": "0",
        "medicamentName_1_volume_15": "0",
        "medicamentName_2_volume_1": "0",
        "medicamentName_5": "",
        "medicamentName_8_volume_2": "0",
        "medicamentName_8_volume_9": "0",
        "medicamentName_9_volume_15": "0",
        "medicamentName_1_volume_13": "0",
        "medicamentName_3_volume_11": "0",
        "medicamentName_5_volume_2": "0",
        "medicamentName_6_volume_1": "0",
        "medicamentName_7_volume_7": "0",
        "medicamentName_7_volume_2": "0",
        "medicamentName_7_volume_3": "0",
        "medicamentName_9_volume_12": "0",
        "medicamentName_1": "消毒水",
        "medicamentName_1_volume_10": "0",
        "medicamentName_2_volume_12": "0",
        "medicamentName_4_volume_1": "0",
        "medicamentName_6_volume_14": "0",
        "medicamentName_1_volume_3": "0",
        "medicamentName_3_volume_2": "0",
        "medicamentName_7_volume_13": "0",
        "medicamentName_7_volume_14": "0",
        "medicamentName_3_volume_8": "0",
        "medicamentName_4": "",
        "medicamentName_4_volume_3": "0",
        "medicamentName_4_volume_12": "0",
        "medicamentName_6_volume_2": "0",
        "medicamentName_7_volume_9": "0",
        "medicamentName_10_volume_12": "0",
        "medicamentName_1_volume_4": "0",
        "medicamentName_5_volume_7": "0",
        "medicamentName_5_volume_12": "0",
        "medicamentName_6_volume_4": "0",
        "medicamentName_6_volume_8": "0",
        "medicamentName_5_volume_8": "0",
        "medicamentName_7": "",
        "medicamentName_1_volume_11": "0",
        "medicamentName_4_volume_5": "0",
        "medicamentName_8_volume_10": "0",
        "medicamentName_8_volume_13": "0",
        "medicamentName_1_volume_1": "0",
        "medicamentName_4_volume_9": "0",
        "medicamentName_5_volume_5": "0",
        "medicamentName_8_volume_12": "0",
        "medicamentName_2_volume_11": "0",
        "medicamentName_3": "",
        "medicamentName_8_volume_4": "0",
        "medicamentName_9_volume_3": "0"
      },
      pump: { // 泵校准
        medicament_1_capacity: '',
        medicament_2_capacity: '',
        medicament_3_capacity: '',
        medicament_4_capacity: '',
        medicament_5_capacity: '',
        medicament_6_capacity: '',
        medicament_7_capacity: '',
        medicament_8_capacity: '',
        medicament_9_capacity: '',
        medicament_10_capacity: '',
        medicament_1_time: '',
        medicament_2_time: '',
        medicament_3_time: '',
        medicament_4_time: '',
        medicament_5_time: '',
        medicament_6_time: '',
        medicament_7_time: '',
        medicament_8_time: '',
        medicament_9_time: '',
        medicament_10_time: '',
        medicament_1_correction: '',
        medicament_2_correction: '',
        medicament_3_correction: '',
        medicament_4_correction: '',
        medicament_5_correction: '',
        medicament_6_correction: '',
        medicament_7_correction: '',
        medicament_8_correction: '',
        medicament_9_correction: '',
        medicament_10_correction: ''
      },
      price: {
        "medicament_1_price": 0,
        "medicament_2_price": 0,
        "medicament_3_price": 0,
        "medicament_4_price": 0,
        "medicament_5_price": 0,
        "medicament_6_price": 0,
        "medicament_7_price": 0,
        "medicament_8_price": 0,
        "medicament_9_price": 0,
        "medicament_10_price": 0
      },
      stat: {},
      timer_status: 0, // 状态计时器状态
      deviceInfo: {}, // 设备所属信息
    }
  },
  methods: {
    // 获取设备所属公司和设备型号
    async getDeviceInfo() {
      let res = await this.$http.post('device/additional-info', {
        sn: this.$route.query.sn
      })
      res = res.data
      if (res.code !== 2000) {
        this.$message.error(this.fun.getLanguageText(this.$store.state.language,res.msg))
        // this.$message.error(res.msg);
        console.log('读取异常', res)
        return;
      }
      this.deviceInfo = res.data
    },
    // 读取当前状态
    async get_stat() {
      let ret = await this.$http.post('device/get-stat', {
        sn: this.$route.query.sn
      })
      if (ret.data.code === 2000) {
        this.stat = ret.data.data
      }
    },
    // 手动调试
    async set_stat(e, val) {
      let data = {
        sn: this.$route.query.sn
      }
      data[e] = val
      const loading = this.$loading({
        lock: true,
        text: '请求中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let ret = await this.$http.post('device/light-button', data)
      loading.close();
      if (ret.data.code != 200) {
        this.$message({
          // message: '下发指令:修改[' + e + ']状态指令失败',
          message: 'error',
          type: 'warning'
        })
        return
      }
      this.$message({
        // message: '下发指令:修改[' + e + ']状态指令成功',
        message: 'success',
        type: 'success'
      })

      /*
      this.timer_load = setTimeout(() => {
        this.$message({
          message: '下发指令:修改[' + this.now_stat_key + ']状态失败',
          type: 'warning'
        })
        this.loading = false
      }, 10000)
      */
    },
    // 下载表单[配方设定1,配方设定2]
    async downloadExcel() {
      import("@/assets/js/Export2Excel").then((excel) => {
        let start = new Date()
        let list = []
        this.tableData.map((item, index) => {
          let obj = []
          console.log(item.name,item.name=="配方名称\\药剂名称")
          if(item.name=="配方名称\\药剂名称"){
            obj[0] = "Formula Name\\Chemical Name"
          }else{
            obj[0] = item.name
          }
         
          for (let i = 1; i < 11; i++) {
            obj[i] = item[i]
          }
          if (index === 0) {
            obj.unshift(this.fun.getLanguageText(this.$store.state.language, "配方编号"))
          } else {
            obj.unshift(index + "")
          }
          list.push(obj)
        })
        // let list_feed = ["首次加药量设定(毫升)", this.first_feed.medicamentTime_1, this.first_feed.medicamentTime_2, this.first_feed.medicamentTime_3, this.first_feed.medicamentTime_4, this.first_feed.medicamentTime_5, this.first_feed.medicamentTime_6, this.first_feed.medicamentTime_7, this.first_feed.medicamentTime_8, this.first_feed.medicamentTime_9, this.first_feed.medicamentTime_10]
        // list.push(list_feed)
        let list_rinse = [
          [this.fun.getLanguageText(this.$store.state.language, "气冲时间(秒)"), null, this.rinse.pump_1_air, null, null, this.rinse.pump_2_air, null, null, this.rinse.pump_3_air, null, this.rinse.pump_4_air, null],
          [this.fun.getLanguageText(this.$store.state.language, "水冲时间(秒)"), null, this.rinse.pump_1_water, null, null, this.rinse.pump_2_water, null, null, this.rinse.pump_3_water, null, this.rinse.pump_4_water, null],
          [this.fun.getLanguageText(this.$store.state.language, "最终水冲时间(秒)"), null, this.rinse.pump_1_final, null, null, this.rinse.pump_1_final, null, null, this.rinse.pump_1_final, null, this.rinse.pump_1_final, null],
        ]
        // pump_1_final
        let aoa = [
          ["TW-2000", "配方明细", null, null, null],
          [null, null, null, null, null],
          [this.fun.getLanguageText(this.$store.state.language, '用户名') + `:${localStorage.getItem('username')}`, `Report Date: ${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`, null, null, null],
          [this.fun.getLanguageText(this.$store.state.language, '公司名称') + ":" + this.deviceInfo.companyName, null, null, null, null],
          [this.fun.getLanguageText(this.$store.state.language, '机器序列号') + ":" + this.$route.query.sn, null, null, null, null],
          [null, null, null, null, null],
          [this.fun.getLanguageText(this.$store.state.language, '洗衣龙') + "", null, this.fun.getLanguageText(this.$store.state.language, '洗衣龙仓1'), null, null, this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓2"), null, null, this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓3"), null, this.fun.getLanguageText(this.$store.state.language, "洗衣龙仓4"), null],
          ...list,
          ...list_rinse,
          [null, null, null, null, null],
          ["TW-2000", null, null, null, null],
          ["P/N:" + this.deviceInfo.deviceModel, null, null, null, null],
          [null, null, null, null, null],
          ["Copyright © " + new Date().getFullYear() + " Hydro Systems", null, null, null, null],
          [null, null, null, null, null]
        ];
        excel.createEXCEL(aoa, this.$store.state.language == 1? "配方导出" : "Formula Settings");
        console.log(aoa)
      })
    },
    handleClick(tab, event) {
      console.log(tab, event);

    },
    async set_system() {
      const loading = this.$loading({
        lock: true,
        text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        loading.close();
      }, 20000);
      let data = this.system
      data.sn = this.$route.query.sn
      let ret = await this.$http.post('device/set-system', data)
      loading.close()
      if (ret?.data?.code === 200) {
        this.$message({
          message: 'success',
          type: 'success'
        });
      } else {
        this.$message({
          message: 'error',
          type: 'warning'
        });
      }
    },
    async get_system(is_log) {
      if (is_log === false) {
        var loading = this.$loading({
          lock: true,
          text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
        }, 20000);
      }
      let ret = await this.$http.post('device/get-system', {
        sn: this.$route.query.sn,
        read: !is_log
      })
      if (is_log === false) {
        loading.close()
      }

      if (ret.data.code === 2000) {
        this.system = ret.data.data
        if (is_log === true) {
          // console.log(is_log)
          return
        }
        this.$message({
          message: 'success',
          type: 'success'
        });
        return
      }
      this.$message({
        message: 'error',
        type: 'warning'
      });
    },
    async set_pump() {
      var loading = this.$loading({
        lock: true,
        text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        loading.close();
      }, 20000);
      let data = this.pump
      data.sn = this.$route.query.sn
      let ret = await this.$http.post('device/pump-calibration', data)
      loading.close();
      if (ret.data.code === 200) {
        // this.system = ret.data.data
        this.$message({
          message: 'success',
          type: 'success'
        });
      } else {
        this.$message({
          message: 'error',
          type: 'warning'
        });
      }
    },
    async get_pump_calibration(is_log) {
      if (is_log === false) {
        var loading = this.$loading({
          lock: true,
          text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
        }, 20000);
      }
      let ret = await this.$http.post('device/get-pump-calibration', {
        sn: this.$route.query.sn,
        read: !is_log
      })
      if (is_log === false) {
        loading.close()
      }
      // console.log(ret.data)
      if (ret.data.code === 2000) {
        this.pump = ret.data.data
        if (is_log === true) {
          return
        }
        this.$message({
          message: 'success',
          type: 'success'
        });
        return
      }
      this.$message({
        message: 'error',
        type: 'warning'
      });
    },
    async set_first_feed() {
      var loading = this.$loading({
        lock: true,
        text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        loading.close();
      }, 20000);
      console.log(this.first_feed)
      let data = this.first_feed
      data.sn = this.$route.query.sn
      let ret = await this.$http.post('device/set-first-feed', data)
      loading.close();
      if (ret.data.code === 200) {
        this.$message({
          message: 'success',
          type: 'success'
        });
      } else {
        this.$message({
          message: 'error',
          type: 'warning'
        });
      }
    },
    async get_first_feed(is_log) {
      if (is_log === false) {
        var loading = this.$loading({
          lock: true,
          text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
        }, 20000);
      }
      let ret = await this.$http.post('device/get-first-feed', {
        sn: this.$route.query.sn,
        read: !is_log
      })
      if (is_log === false) {
        loading.close()
      }
      // console.log(ret.data)
      if (ret.data.code === 2000) {
        this.first_feed = ret.data.data
        if (is_log === true) {
          return
        }
        this.$message({
          message: 'success',
          type: 'success'
        });
        return
      }
      this.$message({
        message: 'error',
        type: 'warning'
      });
    },
    async get_reagent(is_log) {
      if (is_log === false) {
        var loading = this.$loading({
          lock: true,
          text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
        }, 20000);
      }
      let ret = await this.$http.post('device/get-reagent', {
        sn: this.$route.query.sn,
        read: !is_log
      })
      if (is_log === false) {
        loading.close()
      }
      // console.log(ret.data)
      if (ret.data.code === 2000) {
        this.reagent = ret.data.data
        if (is_log === true) {
          return
        }
        this.$message({
          message: 'success',
          type: 'success'
        });
        return
      }
      this.$message({
        message: 'error',
        type: 'warning'
      });
    },
    async set_reagent() {
      var loading = this.$loading({
        lock: true,
        text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        loading.close();
      }, 20000);
      let data = this.reagent
      data.sn = this.$route.query.sn
      let ret = await this.$http.post('device/reagent-correction', data)
      loading.close();
      if (ret.data.code === 200) {
        // this.system = ret.data.data
        this.$message({
          message: 'success',
          type: 'success'
        });
      } else {
        this.$message({
          message: 'error',
          type: 'warning'
        });
      }
    },
    async set_formulation() {
      var loading = this.$loading({
        lock: true,
        text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        loading.close();
      }, 20000);
      console.log(this.tableData)
      this.tableData.map((item, index) => {
        if (index === 0) {
          for (let i = 0; i < 10; i++) {
            this.formulation[`medicamentName_${i + 1}`] = item[i + 1]
          }
          return
        }
        this.formulation[`recipe_name_${index}`] = item.name
        for (let i = 0; i < 10; i++) {
          this.formulation[`medicamentName_${i + 1}_volume_${index}`] = item[i + 1]
        }
      })
      console.log(this.formulation)
      let data = this.formulation
      data.sn = this.$route.query.sn
      let ret = await this.$http.post('device/set-formulation', data)
      loading.close();
      if (ret.data.code === 200) {
        // this.system = ret.data.data
        this.$message({
          message: 'success',
          type: 'success'
        });
      } else {
        this.$message({
          message: 'error',
          type: 'warning'
        });
      }
    },
    async get_formulation(is_log) {
      if (is_log === false) {
        var loading = this.$loading({
          lock: true,
          text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
        }, 20000);
      }
      let ret = await this.$http.post('device/get-formulation', {
        sn: this.$route.query.sn,
        read: !is_log
      })
      if (is_log === false) {
        loading.close()
      }
      if (ret.data.code === 2000) {
        this.formulation = ret.data.data
        let list = []
        let obj = {}
        for (let i = 1; i < 16; i++) {
          obj = {}
          obj.name = ret.data.data["recipe_name_" + i]
          for (let j = 1; j < 11; j++) {
            obj[j] = ret.data.data["medicamentName_" + j + "_volume_" + i]
          }
          list[i - 1] = obj
        }
        // list.map((item, index) => {
        //   item.name = ret.data.data["recipe_name_" + j]
        // })
        obj = {}
        for (let i = 1; i < 11; i++) {
          obj[i] = ret.data.data["medicamentName_" + i]
        }
        obj.name = `配方名称\\药剂名称`
        list.unshift(obj)
        this.tableData = list
        console.log(list)
        if (is_log === true) {
          return
        }
        this.$message({
          message: 'success',
          type: 'success'
        });
        return
      }
      this.$message({
        message: 'error',
        type: 'warning'
      });
    },
    async get_rinse(is_log) {
      if (is_log === false) {
        var loading = this.$loading({
          lock: true,
          text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
        }, 20000);
      }
      let ret = await this.$http.post('device/get-rinse', {
        sn: this.$route.query.sn,
        read: !is_log
      })
      if (is_log === false) {
        loading.close()
      }
      console.log(ret)
      if (ret.data.code === 2000) {
        this.rinse = ret.data.data
        // console.log(this.rinse)
        if (is_log === true) {
          return
        }
        this.$message({
          message: 'success',
          type: 'success'
        });
        return
      }
      this.$message({
        message: 'error',
        type: 'warning'
      });
    },
    async set_rinse() {
      var loading = this.$loading({
        lock: true,
        text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        loading.close();
      }, 20000);
      let data = this.rinse
      data.sn = this.$route.query.sn
      let ret = await this.$http.post('device/rinse-rime', data)
      loading.close();
      if (ret.data.code === 200) {
        // this.system = ret.data.data
        this.$message({
          message: 'success',
          type: 'success'
        });
      } else {
        this.$message({
          message: 'error',
          type: 'warning'
        });
      }
    },
    async get_price(is_log) {
      if (is_log === false) {
        var loading = this.$loading({
          lock: true,
          text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
        }, 20000);
      }
      let ret = await this.$http.post('device/get-price', {
        sn: this.$route.query.sn,
        read: !is_log
      })
      if (is_log === false) {
        loading.close()
      }
      console.log(ret)
      if (ret.data.code === 2000) {
        this.price = ret.data.data
        if (is_log === true) {
          return
        }
        this.$message({
          message: 'success',
          type: 'success'
        });
        return
      }
      this.$message({
        message: 'error',
        type: 'warning'
      });
    },
    async set_price() {
      const loading = this.$loading({
        lock: true,
        text: this.fun.getLanguageText(this.$store.state.language, '等待请求'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        loading.close();
      }, 20000);
      let data = this.price
      data.sn = this.$route.query.sn
      let ret = await this.$http.post('device/set-price', data)
      loading.close();
      if (ret.data.code === 2000) {
        // this.system = ret.data.data
        this.$message({
          message: 'success',
          type: 'success'
        });
      } else {
        this.$message({
          message: 'error',
          type: 'warning'
        });
      }
    },
    getListHeight() {
      let Html = document.getElementsByClassName('box-card')
      if (Html.length > 0) {
        this.$store.state.clientHeight = Html[0].clientHeight - 131 - 80
      }
    },
  },
  mounted() {
    this.getListHeight()
    window.onresize = () => {
      return (() => {
        this.getListHeight()
      })();
    }
  },
  destroyed() {
    window.onresize = null;
    clearInterval(this.timer_status)
  },
  created() {
    this.get_system(true)
    this.get_pump_calibration(true)
    this.get_first_feed(true)
    this.get_reagent(true)
    this.get_formulation(true)
    this.get_rinse(true)
    this.get_price(true)
    this.get_stat()
    this.timer_status = setInterval(() => {
      this.get_stat()
    }, 10000)
    this.getDeviceInfo()
  }
}
</script>
<style lang="less">
.el-table thead.is-group th {
  background: #FFFFFF;
}

.set1 thead {
  tr:nth-child(2) {
    th {
      display: none;
    }
  }
}
</style>
<style lang="less" scoped>
.showImg {
  // width: 100%;
  // height: 100%;
  background-color: #F5F5F5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.el-form-item {
  width: 46%;

  .el-input {
    width: 200px;
  }

  .el-button {
    width: 200px;
    margin-right: 90px;
  }
}

.el-input-none {
  width: 200px;
  padding-left: 20px;
  padding-right: 20px;
}

.title {
  font-size: 20px;
  margin-bottom: 14px;
}


.el-form-four {
  .el-form-item {
    // width: 300px;
    // margin-right: 20px;
  }

  .el-select {
    width: 400px;
  }
}

.debug {
  .el-button {
    width: 100px;
    margin-right: 0;
  }

  .el-form-item {
    width: 280px;
  }
}
</style>
